<template>
  <DefaultTemplate page="forgot">
    <Layout>
      <div class="main-slot text-white padding-grid">
        <h1 class="text-center font-weight-bold">ลืมรหัสผ่าน?</h1>
        <p class="text-center">
          <!-- พี่แป้งให้เอา br หน้าข้อความ'การขอรหัสผ่านใหม่'ออก 19/8/2021 -->
          โปรดใส่อีเมลที่ท่านได้ทำการลงทะเบียนเพื่อยืนยันการขอรหัสผ่านใหม่
        </p>
        <el-input
          v-model="email"
          @keyup.enter.native="send()"
          aria-label="Email"
        ></el-input>
        <div class="w-100 mg-t-2">
          <el-row :gutter="20" type="flex" justify="center">
            <el-col :sm="8" :md="6" :offset="0">
              <div class="signin-button">
                <el-button
                  class="w-100"
                  type="danger font-22"
                  @click="send()"
                  :loading="loading"
                  >ขอรหัสผ่านใหม่</el-button
                >
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </Layout>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDefaultTemplate";
export default {
  components: {
    DefaultTemplate,
    Layout
  },
  data() {
    return {
      email: "",
      loading: false
    };
  },
  methods: {
    send() {
      this.loading = true;
      HTTP.post("forgot/password", { email: this.email })
        .then(res => {
          if (res.data.success) {
            this.$message({
              message:
                "เราได้ส่งอีเมลแล้ว กรุณาตรวจสอบอีเมลเพื่อทำการตั้งรหัสผ่านใหม่",
              type: "success"
            });
            this.loading = false;
            this.$router.push("/sign-in?vdo=no");
          } else {
            this.loading = false;
            this.$message({
              message: "ขออภัย กรุณาตรวจสอบอีเมลของท่านอีกครั้ง",
              type: "error"
            });
          }
        })
        .catch(e => {
          console.log("forgot/password error", e);
          this.loading = false;
          this.$message({
            message: "ขออภัย กรุณาตรวจสอบอีเมลของท่านอีกครั้ง",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: calc(100vh - 402px);
}

@media (min-width: 1281px) {
  .main-slot {
    min-height: calc(100vh - 343px) !important;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .main-slot {
    min-height: calc(100vh - 343px) !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main-slot {
    min-height: calc(100vh - 344px) !important;
  }
}

@media (max-width: 767px) {
  .main-slot {
    min-height: calc(100vh - 345px) !important;
  }
}

@media (max-width: 414px) {
  .main-slot {
    min-height: calc(100vh - 352px) !important;
  }
}

@media (max-width: 375px) {
  .main-slot {
    min-height: calc(100vh - 346px) !important;
  }
}
</style>